import React,{useEffect} from 'react'
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
// import NavDropdown from 'react-bootstrap/NavDropdown';
import { useLocation, Link } from 'react-router-dom';
import Labels from '../language/labels';

import { FiMenu,FiX } from "react-icons/fi";
function BootstrapNavbar() {
  let location = useLocation();

  useEffect(() => {
    // Google Analytics
console.log(location.pathname)
  }, [location]);
        return(
          <div className={`header ${location.pathname==="/Home" || location.pathname==="/"? 'home':""}`}>
          <Navbar  expand="lg"  variant="dark" style={{backgroundColor:`${location.pathname==="/Home" || location.pathname==="/" ? '':"#fff"}`}}>
          <Container >
          <a href="/" className='text-decoration-none'>
            <div className='d-flex align-items-center '>
              
            <Navbar.Brand ><img src="https://cdn.superme.al/s/shoply/images/logo-square.png" width="auto" height="45" alt="" class="navbar-brand-full"/> 
            <a class="ml-2 logo-text">Supershoply</a>
            </Navbar.Brand>
           
            </div>
            </a>
            <a className="text-white medium-btn resp-h ml-lg-4 d-lg-none d-flex font-16" href="https://register.supershoply.com" target='_blank'>{Labels.Free_Trial}</a>
            {/* <Navbar.Brand  className='ml-auto d-lg-none medium-btn resp-h  mr-3 font-16 border-bottom' href="https://register.supershoply.com" target='_blank'>{Labels.Free_Trial}</Navbar.Brand> */}
            {/* <Navbar.Toggle className='burger-menu'>
             <span className='burger'>
             <FiMenu size="1.5em"/>

              </span>
              <span className='cross'>
              <FiX size="1.5em"/>
              </span>
            </Navbar.Toggle> */}
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="me-auto w-100 d-flex align-items-center justify-content-end">
               {/* <Link  to="/Home" className={`ml-lg-auto text-white ${location.pathname==="/Home"?'active':''}`}>{Labels.Home}</Link> */}
              
              
               {/* <a  href="/Subscription" className={`w-100-res text-white ml-lg-auto ${location.pathname==="/Subscription"?'active':''}`}>{Labels.Pricing}</a> */}
               

               <a className="text-white medium-btn ml-lg-4 d-none d-lg-flex font-16" href="https://register.supershoply.com" target='_blank'>{Labels.Free_Trial}</a>
               

                {/* <NavDropdown title="Dropdown" id="basic-nav-dropdown">
                  <NavDropdown.Item href="#action/3.1">Action</NavDropdown.Item>
                  <NavDropdown.Item href="#action/3.2">
                    Another action
                  </NavDropdown.Item>
                  <NavDropdown.Item href="#action/3.3">Something</NavDropdown.Item>
                  <NavDropdown.Divider />
                  <NavDropdown.Item href="#action/3.4">
                    Separated link
                  </NavDropdown.Item>
                </NavDropdown> */}
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
            
             </div>
        )  
    
}

export default BootstrapNavbar;