import React from 'react'
// import {Link } from "react-router-dom";
import Messages from '../language/ConsumerMessages';
import Labels from '../language/labels';
// import Navbar from '../Components/Navbar'
// import Footer from '../Components/footer';
function TermCondition() {
  return (
    <div className='mb-5'> 
    {/* <Helmet>
        <title>Terms and Conditions | Privacy Policy</title>
        <meta property="og:title" content="Terms and Conditions | Privacy Policy" />
        <meta property="og:description" content="Eliminate outdated paper forms, frustrating phone calls, and long waiting times - Supershoply makes hotel and hospitality services easy and efficient." />
        </Helmet> */}
         
          {/* <Navbar/> */}
          <div className='container'>
          <div class="term-conditions-wrapper">
        <p>I. <a style={{textDecoration:"none", color:'#d10101'}} href="#TERMS_AND_CONDITIONS_OF_USE_AND_SALE">Terms and Conditions of Use and Sale</a></p>
        <p>II. <a style={{textDecoration:"none", color:'#d10101'}} href="#SUPERSHOPLY_PRIVACY_POLICY">Privacy Policy</a></p>
        {/* <p>III. <a style={{textDecoration:"none", color:'#d10101'}} href="#SUPERSHOPLY_VOUCHER_TERMS_CONDITIONS">Voucher Terms and Conditions</a></p>
        <p>IV. <a style={{textDecoration:"none", color:'#d10101'}} href="#SUPERSHOPLY_COMPETITIONS_TERMS_CONDITIONS">Competitions Terms and Conditions</a></p> */}
        <p>III. <a style={{textDecoration:"none", color:'#d10101'}} href="#COOKIES_POLICY">Cookies Policy</a></p>
        <p>
            <strong>SUPERSHOPLY WEBSITE TERMS AND CONDITIONS</strong>
        </p>
        <p>
            <strong>IMPORTANT LEGAL NOTICE</strong>
        </p>
        <p>
            This page (together with our ) sets out the terms and conditions ( <strong>"Website Terms"</strong>) on which we, Supershoply.com (<strong>"we"</strong> or <strong>"SUPERSHOPLY"</strong>), provide our services through our website
            and any SUPERSHOPLY mobile application through which you access our website or services (together, <strong>"Website"</strong>). Please read these Website Terms carefully before ordering any products through, the Website, as your
            purchase of any products offered on the Website is subject to these Website Terms. By ordering products via the Website (whether now or in the future), you agree to be bound by these Website Terms. Use of the Website is also subject
            to these Website Terms.
        </p>
        <p>
            We reserve the right to change these Website Terms from time to time by changing them on this page. We advise you to print a copy of these Website Terms for future reference. These Website Terms are only in the English language.
        </p>
        <p>
            Use of your personal information submitted via the Website is governed by our <a style={{textDecoration:"none", color:'#d10101'}} href="#SUPERSHOPLY_PRIVACY_POLICY">Privacy Policy</a> and
            <a style={{textDecoration:"none", color:'#d10101'}} href="#">Cookies Policy</a>.
        </p>
        <p>
            For the avoidance of doubt, please note that references to <strong>"Website"</strong>
            in these Website Terms include any current or future version of our website and any SUPERSHOPLY mobile application through which you access our website or services, in each case whether accessed through any current or future
            platform or device (including without limitation any mobile website, mobile application, affiliate website or related website for accessing our website or services that may be developed from time to time).
        </p>
        <p>
            By accessing any part of the Website, you indicate that you accept these Website Terms. If you do not accept these Website Terms, you should leave the Website immediately, and you will not be able to order any products through the
            Website.
        </p>
        <p><a name="TERMS_AND_CONDITIONS_OF_USE_AND_SALE"></a><strong>I. TERMS AND CONDITIONS OF USE AND SALE</strong></p>
        <p>
            <strong>1. INTRODUCTION AND OUR ROLE</strong>
        </p>
        <p>
            <strong>1.1. Company details: </strong>
            WaqarTech Limited trading as SUPERSHOPLY is a company registered in England and Wales with registered company number 06817183 Unit A, Aston Seedbed Centre, Avenue Rd, Nechells, Birmingham, B7 4NT
        </p>
        <p>
            <strong>1.2. VAT number:</strong>
            Our VAT number is 104908332<strong></strong>
        </p>
        <p>
            <strong>1.3. Service:</strong> We provide a way for you to communicate your orders (<strong>"Orders"</strong>) for products ( <strong>"Products"</strong>) to delivery or takeaway hotels (<strong>"Hotels"</strong>) displayed on the
            Website (the<strong>"Service"</strong>).
        </p>
        <p>
            <strong>2. WEBSITE ACCESS AND TERMS</strong>
        </p>
        <p>
            <strong>2.1. Website access:</strong>
            You may access some areas of the Website without making an Order or registering your details with us. Most areas of the Website are open to everyone.
        </p>
        <p>
            <strong>2.2. Acceptance of terms:</strong>
            By accessing any part of the Website, you indicate that you accept these Website Terms. If you do not accept these Website Terms, you should leave the Website immediately, and you will not be able to order any Products through the
            Website.
        </p>
        <p>
            <strong>2.3. Revision of terms:</strong>
            We may revise these Website Terms at any time. You should check the Website regularly to review the current Website Terms, because they are binding on you. You will be subject to the policies and terms and conditions in force at the
            time that you place an Order through us.
        </p>
        <p>
            <strong>2.4. Responsibility:</strong>
            You are responsible for making all arrangements necessary for you to have access to the Website. You are also responsible for ensuring that all persons who access the Website through your Internet connection are aware of these
            Website Terms and that they comply with them.
        </p>
        <p>
            <strong>3. YOUR STATUS</strong>
        </p>
        <p>
            <strong>3.1. Capacity and age:</strong>
            By placing an Order through the Website, you warrant that:
        </p>
        <p>
            3.1.1. You are legally capable of entering into binding contracts; and
        </p>
        <p>
            3.1.2. You are at least 18 years old.
        </p>
        <p>
            3.1.3 You agree not to impersonate any other person or entity or to use a false name or a name that you are not authorised to use.
        </p>
        <p>
            3.1.4 Members' passwords should be kept confidential at all times, and must not be shared or disclosed to anyone. You are responsible for all activities and orders that occur or are submitted through your membership. If you know or
            suspect that someone has knowledge of your password then you should <a style={{textDecoration:"none", color:'#d10101'}} href="#">contact us</a>
            immediately.
        </p>
        <p>
            <strong>3.2. Alcohol and cigarettes:</strong>
            Furthermore, you acknowledge and agree that:
        </p>
        <p>
            3.2.1. Pursuant to the Licensing Act 1964, it is an offence for any person under the age of 18 to buy, or attempt to buy, intoxicating liquor, or for any person over the age of 18 to buy intoxicating liquor on behalf of any person
            under the age of 18;
        </p>
        <p>
            3.2.2. Cigarettes are not for sale to persons under the age of 18; and
        </p>
        <p>
            3.2.3. Orders containing either alcohol or cigarettes can therefore not be accepted from or on behalf of persons under the age of 18.
        </p>
        <p>
            3.2.4 Proof of age may be demanded by the delivery driver. If this is not provided the order will not be delivered and a refund will not be made.
        </p>
        <p>
            <strong>4. HOW TO MAKE AN ORDER AND HOW IT IS PROCESSED</strong>
        </p>
        <p>
            <strong>4.1. Compiling your Order:</strong>
            Once you have selected the Products you wish to order from the menu of your chosen Hotel and provided the other required information, you will be given the opportunity to submit your Order by clicking or selecting the "proceed",
            "place my order" or similar button. It is important that you check all the information that you enter and correct any errors before clicking or selecting this button; once you do so we will start processing your Order and errors
            cannot be corrected.
        </p>
        <p>
            4.2 Note that pictures of the product are for illustrative purposes only and may not represent the actual product delivered. Neither Supershoply or the Hotel are responsible for this discrepancy.
        </p>
        <p>
            <strong>4.3. Amending or cancelling your Order:</strong>
            Once you have submitted your Order and your payment has been authorised, you will not be entitled to change or cancel your Order, nor will you be entitled to a refund (please refer to paragraph 4.5 for details of the process
            relating to rejected Orders). If you wish to change or cancel your Order, you may contact our Customer Care team as described in paragraph 6.3 and they will attempt to contact the Hotel in order to communicate your requests.
            However, there is no guarantee that we will be able to reach the Hotel or that the Hotel will agree to your requests as they may have already started processing your Order.
        </p>
        <p>
            <strong>4.4. Payment authorisation:</strong>
            Where any payment you make is not authorised, your Order will not be processed or communicated to the relevant Hotel.
        </p>
        <p>
            <strong>4.5. Processing your Order and Hotel rejections:</strong>
            On receipt of your Order, we will begin processing it by sending it to the relevant Hotel and will notify you by email that your Order has been received and is being processed. Please note that any confirmation page that you may see
            on the Website and any Order confirmation e-mail that you may receive each merely indicate that your Order has been received and is being processed by us, and does not necessarily mean that your Order has been accepted by the Hotel.
            We encourage all our Hotels to accept all Orders and to communicate any rejection promptly, and we will notify you ( by email or text) as soon as reasonably practicable if a Hotel rejects your Order. However, Hotels have the
            discretion to reject Orders at any time because they are too busy, due to weather conditions or for any other reason.
        </p>
        <p>
            <strong>4.6. Delivery of your Order:</strong>
            Estimated times for deliveries and collections are provided by the Hotels and are only estimates. Neither we nor the Hotels guarantee that Orders will be delivered or will be available for collection within the estimated times.
        </p>
        <p>
            4.7. <strong>Contact number:</strong> Please ensure that the mobile number or landline number you provided is a working phone as we may send your order acceptance confirmation to your mobile number or confirmation call on landline
            number.
        </p>
        <p>
            <strong>5. PRICE AND PAYMENT</strong>
        </p>
        <p>
            <strong>5.1. VAT and delivery costs:</strong>
            Prices will be as quoted on the Website. These prices include VAT but may exclude delivery costs (if you opt for delivery instead of collection) and any online payment administration charge imposed by the Hotel (if you pay for your
            Order online). These will be added to the total amount due where applicable.
        </p>
        <p>
            <strong>5.2. Incorrect pricing:</strong>
            This Website contains a large number of menus and it is possible that some of the menus may include incorrect prices. If the correct price for an Order is higher than the price stated on the Website, we will normally contact you
            before the relevant Order is dispatched. In such an event, neither we nor the relevant Hotel is under any obligation to ensure that the Order is provided to you at the incorrect lower price or to compensate you in respect of
            incorrect pricing.
        </p>
        <p>
            <strong>5.3. Payment methods:</strong>
            Payment for Orders must be made by an accepted credit or debit card through the Website or in cash to the Hotel at the point of delivery to you.
        </p>
        <p>If you pay by credit or debit card, our website will require CVV2/CVC2 verification.</p>
    
        <p>Our website also supports 3D Secure transactions.</p>
    
        <p></p>
        <p>
            <strong>5.4. Card payments:</strong>
            If you pay by credit or debit card, you will be required to show the card to the Hotel at the time of delivery as proof of identification and so that they can check that the card conforms with the receipt data for the Order. Please
            note that from time to time there may be delays with the processing of card payments and transactions; this may result in payments taking up to sixty (60) days to be deducted from your bank account or charged to your credit or debit
            card.
        </p>
        <p>
            <strong>5.5. Credit and discount vouchers:</strong>
            A credit or discount may apply to your Order if you use a promotional voucher or code recognised by the Website and endorsed by SUPERSHOPLY, and you pay for any balance by credit or debit card. Please refer to our
            <a style={{textDecoration:"none", color:'#d10101'}} href="#SUPERSHOPLY_VOUCHER_TERMS_CONDITIONS">Voucher Terms &amp; Conditions</a> for the full terms and conditions applicable to the use of credit and discount vouchers. Please note
            that because of standard banking procedures, your bank or card issuer will initially "ring-fence" the full amount of the Order (before any credit or discount) in your account for between 3 to 5 working days (or longer, depending on
            your bank or card issuer), and this amount will therefore be unavailable in your account for that period. The credit or discount will be applied at the time your bank or card issuer transfers the funds for your Order to us, at which
            point the credit or discounted amount will not be transferred to us and will instead be released by your bank or card issuer back into your available balance. You acknowledge and agree that neither we nor the relevant Hotel will be
            responsible or liable to you in relation to this delay by your bank or card issuer in the release of funds back into your account.
        </p>
        <p>
            <strong>5.6. Rejected Orders:</strong>
            Because of standard banking procedures, once you have submitted an Order that you are paying for by credit or debit card and your payment has been authorised, your bank or card issuer will "ring-fence" the full amount of your Order.
            If your Order is subsequently rejected by the Hotel (as described in paragraph 4.5 above) or cancelled for any other reason, your bank or card issuer will not transfer the funds for the Order to us, and will instead release the
            relevant amount back into your available balance. However, this may take between 3 to 5 working days (or longer, depending on your bank or card issuer). You acknowledge and agree that neither we nor the relevant Hotel will be
            responsible or liable to you in relation to this delay by your bank or card issuer in the release of funds back into your account.
        </p>
        <p>
            <strong>5.7.</strong>
            <strong>Reward Points/Cash:</strong>
            Members of the Site have the opportunity to collect cash-back rewards when ordering from participating hotels on Supershoply.com (indicated by the presence of a special icon). Cash-back is earned when an order is placed with a
            participating hotel on the Site and the order is Accepted by the given hotel. Cash are collected against all order and against all hotels. You may earn a maximum number of cash per hotel per day depending upon the order total and
            cash-back percentage. Once you have collected more than 10 UK Sterling Pounds or more, you are able to spend them. The earned cash is shown in eWallet.
        </p>
        <p>
            Cash-back percentage for every Hotel may change without notice, and the offer may be withdrawn either from individual hotels or from the whole of the Site without prior notice and with immediate effect. In such circumstances, any
            discounts due and cash-back collected will be void, and neither the hotels nor Supershoply.com may be held liable for any losses incurred as a result.
        </p>
        <p>
            The cash-back reward is non-exchangeable, and may only be used as described in these Terms of Use.
        </p>
        <p>
            <strong>6. CUSTOMER CARE</strong>
        </p>
        <p>
            <strong>6.1. General:</strong>
            Customer care is extremely important to us. Subject to paragraphs 6.5 and 11, our Customer Care team will therefore try to assist you where possible if you have any problems with your Order. You can contact our Customer Care team by
            clicking or selecting the "Help" or similar button or by calling the telephone number shown on the Website.
        </p>
        <p>
            <strong>6.2. Questions about your Order:</strong>
            If your Order is taking longer than expected or you have any other problems with your Order, you can contact our Customer Care Team as described above and one of our Customer Care Advisers will attempt to contact the Hotel in order
            to follow up on your query.
        </p>
        <p>
            <strong>6.3. Changing or cancelling your Order:</strong>
            If you wish to change or cancel your Order after it has been submitted and payment has been authorised, you may contact our Customer Care team as described above and they will attempt to contact the Hotel in order to communicate
            your requests. However, there is no guarantee that we will be able to reach the Hotel or that the Hotel will agree to your requests as they may have already started processing your Order.
        </p>
        <p>
            <strong>6.4. Complaints or feedback:</strong>
            In the event that you are dissatisfied with the quality of any Products or the service provided by a Hotel, please consider providing feedback in the form of ratings, comments and reviews on the Website (together, "Reviews") to
            reflect your experience. The Reviews are an important part of our quality control process. Supershoply may remove any comments that it determines in its sole discretion.The Hotel will have a right to comment on your feedback.
        </p>
        <p>
            <strong>6.5. Compensation:</strong>
            If you are dissatisfied with the quality of any Products or the service provided by a Hotel and wish to seek a refund, a proportionate price reduction or any other compensation, you should contact the Hotel directly to lodge your
            complaint and, where appropriate, follow the Hotel's own complaint procedures. If you are unable to contact the Hotel, or the Hotel refuses to deal with your complaint, you can contact our Customer Care Team as described above
            within 48 hours of placing your Order and one of our Customer Care Advisers will attempt to contact the Hotel in order to request compensation on your behalf. Please note that we have no control over Hotels and the quality of the
            Products or service that they provide, and we not able to provide, and have no responsibility or liability for providing, any compensation to you on behalf of any Hotel.
        </p>
        <p>
            <strong>7. LICENCE</strong>
        </p>
        <p>
            <strong>7.1. Terms of permitted use:</strong>
            You are permitted to use the Website and print and download extracts from the Website for your own personal non-commercial use on the following basis:
        </p>
        <p>
            7.1.1. You must not misuse the Website (including by hacking or "scraping").
        </p>
        <p>
            7.1.2. Unless otherwise stated, the copyright and other intellectual property rights in the Website and in material published on it (including without limitation photographs and graphical images) are owned by us or our licensors.
            These works are protected by copyright laws and treaties around the world and all rights are reserved. For the purposes of these Website Terms, any use of extracts from the Website other than in accordance with paragraph 7.1 is
            prohibited.
        </p>
        <p>
            7.1.3. You must not modify the digital or paper copies of any materials that you print off in accordance with paragraph 7.1 and you must not use any pictures, photographs or any other graphics, video or audio sequences separately
            from any accompanying text.
        </p>
        <p>
            7.1.4. You must ensure that our status as the author of the material on the Website is always acknowledged.
        </p>
        <p>
            7.1.5. You are not allowed to use any of the materials on the Website or the Website itself for commercial purposes without obtaining a licence from us to do so.
        </p>
        <p>
            <strong>7.2. Limitation on use:</strong>
            Except as stated in paragraph 7.1, the Website may not be used, and no part of the Website may be reproduced or stored in any other website or included in any public or private electronic retrieval system or service, without our
            prior written permission.
        </p>
        <p>
            <strong>7.3. Reservation of rights:</strong>
            Any rights not expressly granted in these Website Terms are reserved.
        </p>
        <p>
            <strong>8. SERVICE ACCESS</strong>
        </p>
        <p>
            <strong>8.1. Website availability:</strong>
            While we try to ensure the Website is normally available twenty four (24) hours a day, we do not undertake any obligation to do so, and we will not be liable to you if the Website is unavailable at any time or for any period.
        </p>
        <p>
            <strong>8.2. Suspension of access:</strong>
            Access to the Website may be suspended temporarily at any time and without notice.
        </p>
        <p>
            <strong>8.3. Information security:</strong>
            The transmission of information via the internet is not completely secure. Although we take the steps required by law to protect your information, we cannot guarantee the security of your data transmitted to the Website; any
            transmission is at your own risk.
        </p>
        <p>
            <strong>9. VISITOR MATERIAL AND REVIEWS</strong>
        </p>
        <p>
            <strong>9.1. General:</strong>
        </p>
        <p>
            9.1.1. Other than personally identifiable information, which is covered under our <a style={{textDecoration:"none", color:'#d10101'}} href="#SUPERSHOPLY_PRIVACY_POLICY">Privacy Policy</a>, any material you post, upload or transmit or
            upload to the Website (including without limitation Reviews) (<strong>"Visitor Material"</strong>) will be considered non-confidential and non-proprietary. By posting, uploading or transmitting any Visitor Material, you represent
            and warrant that you own or otherwise control all of the rights to such Visitor Material. You agree that we will have no obligations with respect to any Visitor Material, and that we and anyone we designate will be free to copy,
            disclose, distribute, incorporate and otherwise use any Visitor Material and all data, images, sounds, text and other things embodied in it for any and all commercial or non-commercial purposes.
        </p>
        <p>
            9.1.2. You represent and warrant that that any Visitor Material you post, upload or transmit does not and will not breach any of the restrictions in paragraphs 9.2 to 9.3 below.
        </p>
        <p>
            <strong>9.2. Visitor Material Policy:</strong>
            You are prohibited from posting, uploading or transmitting to or from the Website any Visitor Material (including any Reviews) that:
        </p>
        <p>
            9.2.1. breaches any applicable local, national or international law;
        </p>
        <p>
            9.2.2. is unlawful or fraudulent;
        </p>
        <p>
            9.2.3. amounts to unauthorised advertising; or
        </p>
        <p>
            9.2.4. contains viruses or any other harmful programs.
        </p>
        <p>
            <strong>9.3. Visitor Reviews Policy:</strong>
            In particular (but without limitation), any Reviews that you submit through the Website must not:
        </p>
        <p>
            9.3.1. contain any defamatory, obscene or offensive material;
        </p>
        <p>
            9.3.2. promote violence or discrimination;
        </p>
        <p>
            9.3.3. infringe the intellectual property rights of another person;
        </p>
        <p>
            9.3.4. breach any legal duty owed to a third party (such as a duty of confidence);
        </p>
        <p>
            9.3.5. promote illegal activity or invade another's privacy;
        </p>
        <p>
            9.3.6. give the impression that they originate from us; or
        </p>
        <p>
            9.3.7. be used to impersonate another person or to misrepresent your affiliation with another person.
        </p>
        <p>
            <strong>9.4. Removal of Reviews:</strong>
            The prohibited acts listed in paragraphs 9.2 and 9.3 above are non-exhaustive. We reserve the right (but do not undertake, except as required by law, any obligation) and have the sole discretion to remove or edit at any time any
            Reviews or other Visitor Material posted, uploaded or transmitted to the Website that we determine breaches a prohibition in paragraphs 9.2 or 9.3 above, is otherwise objectionable or may expose us or any third parties to any harm
            or liability of any type, or for any other reason.
        </p>
        <p>
            <strong>9.5. Use of Reviews:</strong>
            The Reviews and other Visitor Material contained on the Website are for information purposes only and do not constitute advice from us. Reviews and Visitor Material reflect the opinions of customers who have ordered through the
            Website or other third parties, and any statements, advice or opinions provided by such persons are theirs only. Accordingly, to the fullest extent permitted by law, we assume no responsibility or liability to any person for any
            Reviews or other Visitor Material, including without limitation any mistakes, defamation, obscenity, omissions or falsehoods that you may encounter in any such materials.
        </p>
        <p>
            <strong>9.6. Liability:</strong>
            You agree to indemnify us against any losses, damages and claims (and all related costs) incurred by or made against us by a Hotel or any other third party arising out of or in connection with any Reviews or other Visitor Material
            that you provide in breach of any of the representations and warranties, agreements or restrictions set forth in this paragraph 9.
        </p>
        <p>
            <strong>9.7. Disclosure to authorities and courts:</strong>
            You acknowledge that we will fully co-operate with any competent authority requesting or directing us to disclose the identity or location of anyone posting any Reviews or other Visitor Material in breach of paragraph 9.2 or 9.3 or
            any other applicable restriction and you release us to the fullest extent permitted by law from all liability in relation to such disclosure.
        </p>
        <p>
            <strong>9.8. Confidential materials:</strong>
            We do not solicit nor wish to receive any confidential or proprietary information from you. Nor do we wish to receive any creative materials, ideas or suggestions other than those we specifically request. If you do send to us by
            email or otherwise, any communication or material, you represent and warrant that the information and material is original to you, and you will be deemed to have granted us a perpetual, world-wide, royalty-free license to use such
            communications or material in any way we see fit and we will be free to use any such material or information contained in communications you send for any purpose whatsoever.
        </p>
        <p>
            <strong>10. LINKS TO AND FROM OTHER WEBSITES</strong>
        </p>
        <p>
            <strong>10.1. Third party websites:</strong>
            Links to third party websites on the Website are provided solely for your convenience. If you use these links, you leave the Website. We have not reviewed and do not control any of these third party websites (and are not responsible
            for these websites or their content or availability). We do not endorse or make any representation about these websites, their content, or the results from using such websites or content. If you decide to access any of the third
            party websites linked to the Website, you do so entirely at your own risk.
        </p>
        <p>
            <strong>10.2. Linking permission:</strong>
            You may link to the Website's homepage (www.Supershoply.com), provided that:
        </p>
        <p>
            10.2.1. you do so in a fair and legal way which does not damage or take advantage of our reputation;
        </p>
        <p>
            10.2.2. you do not establish a link from a website that is not owned by you or in a way that suggests a form of association with or endorsement by us where none exists;
        </p>
        <p>
            10.2.3. any website from which you link must comply with the content standards set out in these Website Terms (in particular paragraph 9 (Visitor Materials and Reviews));
        </p>
        <p>
            10.2.4. we have the right to withdraw linking permission at any time and for any reason.
        </p>
        <p>
            <strong>11. DISCLAIMERS</strong>
        </p>
        <p>
            <strong>11.1. Website information:</strong>
            While we try to ensure that information on the Website is correct, we do not promise it is accurate or complete. We may make changes to the material on the Website, or to the Service, Products and prices described on it, at any time
            without notice. The material on the Website may be out of date, and we make no commitment to update that material.
        </p>
        <p>
            <strong>11.2. Allergy, dietary and other menu information:</strong>
            We try to accurately copy the item names, descriptions, prices, special offer information, heat and allergenic warnings and other information ("Menu Information") from the menus that are provided to us by Hotels. However, it is the
            Hotels that are responsible for providing this Menu Information and ensuring that it is factually accurate and up-to-date, and we do not undertake any such responsibility. If you are in doubt about allergy warnings, contents of a
            dish or any other Menu Information, you should confirm with the Hotel directly before ordering.
        </p>
        <p>
            <strong>11.3. Hotel actions and omissions:</strong>
            The legal contract for the supply and purchase of Products is between you and the Hotel that you place your Order with. We have no control over the actions or omissions of any Hotels. Without limiting the generality of the
            foregoing, you acknowledge and accept the following by using the Website:
        </p>
        <p>
            11.3.1. We do not give any undertaking that the Products ordered from any Hotel through the Website will be of satisfactory quality or suitable for your purpose and we disclaim any such warranties.
        </p>
        <p>
            11.3.2. Estimated times for deliveries and collections are provided by the Hotels and are only estimates. Neither we nor the Hotels guarantee that Orders will be delivered or will be available for collection within the estimated
            times.
        </p>
        <p>
            11.3.3. We encourage all our Hotels to accept all Orders and to communicate any rejection promptly, and we will notify you (generally by email) as soon as reasonably practicable if a Hotel rejects your Order. However, we do not
            guarantee that Hotels will accept all Orders, and Hotels have the discretion to reject Orders at any time because they are too busy, due to weather conditions or for any other reason.
        </p>
        <p>
            11.3.4. The foregoing disclaimers do not affect your statutory rights against any Hotel.
        </p>
        <p>
            <strong>11.4. Exclusion of terms:</strong>
            We provide you with access to the Website and Service on the basis that, to the maximum extent permitted by law, we exclude all representations, warranties, conditions, undertakings and other terms in relation to the Website and
            Service (including any representations, warranties, conditions, undertakings and other terms which might otherwise apply to the Website or Service, or be otherwise implied or incorporated into these Website Terms, by statute, common
            law or otherwise ).
        </p>
        <p>
            <strong>12. LIABILITY</strong>
        </p>
        <p>
            <strong>12.1. General:</strong>
            Nothing in these Website Terms excludes or limits our liability for death or personal injury arising from our negligence, our liability for fraudulent misrepresentation, or any other liability which cannot be excluded or limited
            under applicable law. Nothing in these Website Terms affects your statutory rights.
        </p>
        <p>
            <strong>12.2. Exclusion of liability:</strong>
            Subject to clause 12.1, we will under no circumstances whatever be liable to you, whether in contract, tort (including negligence), breach of statutory duty, or otherwise, even if forseeable, arising under or in connection with the
            Service or the Website (including the use, inability to use or the results of use of the Service or the Website) for:
        </p>
        <p>
            12.2.1. any loss of profits, sales, business, or revenue;
        </p>
        <p>
            12.2.2. loss or corruption of data, information or software;
        </p>
        <p>
            12.2.3. loss of business opportunity;
        </p>
        <p>
            12.2.4. loss of anticipated savings;
        </p>
        <p>
            12.2.5. loss of goodwill; or
        </p>
        <p>
            12.2.6. any indirect or consequential loss.
        </p>
        <p>
            <strong>12.3. Limitation of liability:</strong>
            Subject to clauses 11, 12.1 and 12.2, our total liability to you in respect of all other losses arising under or in connection with the Service or the Website, whether in contract, tort (including negligence), breach of statutory
            duty, or otherwise, shall in no circumstances exceed twice the value of your Order or £100, whichever is lower.
        </p>
        <p>
            <strong>12.4. Additional costs:</strong>
            You assume full and sole responsibility for any additional or associated costs that you may incur in connection with or as a result of your use of the Website, including without limitation costs relating to the servicing, repair or
            adaptation of any equipment, software or data that you may own, lease, license or otherwise use.
        </p>
        <p>
            <strong>13. TERMINATION</strong>
        </p>
        <p>
            <strong>13.1. Grounds for termination:</strong>
            We may terminate or suspend (at our absolute discretion) your right to use the Website and the Service immediately by notifying you in writing (including by email) if we believe in our sole discretion that:
        </p>
        <p>
            13.1.1. you have used the Website in breach of paragraph 7.1 (License);
        </p>
        <p>
            13.1.2. you have posted Reviews or other Visitor Material in breach of paragraphs 9.2 or 9.3 (Visitor Material and Reviews);
        </p>
        <p>
            13.1.3. you have breached paragraph 10.2 (Links to and from other websites); or
        </p>
        <p>
            13.1.4. you have breached any other material terms of these Website Terms.
        </p>
        <p>
            <strong>13.2. Obligations upon termination:</strong>
            Upon termination or suspension you must immediately destroy any downloaded or printed extracts from the Website.
        </p>
        <p>
            <strong>14. WRITTEN COMMUNICATIONS</strong>
        </p>
        <p>
            14.1. Applicable laws require that some of the information or communications we send to you should be in writing. When using the Website or ordering Products via the Website, you accept that communication with us will be mainly
            electronic. We will contact you by email or provide you with information by posting notices on the Website. For contractual purposes, you agree to this electronic means of communication and you acknowledge that all contracts,
            notices, information and other communications that we provide to you electronically comply with any legal requirement that such communications be in writing. This condition does not affect your statutory rights.
        </p>
        <p>
            <strong>15. EVENTS OUTSIDE OUR CONTROL</strong>
        </p>
        <p>
            15.1. We will not be liable or responsible for any failure to perform, or delay in performance of, any of our obligations under these Website Terms that is caused by events outside our reasonable control (
            <strong>"Force Majeure Event"</strong>).
        </p>
        <p>
            15.2. A Force Majeure Event includes any act, event, non-occurrence, omission or accident beyond our reasonable control and includes in particular (without limitation) the following:
        </p>
        <p>
            15.2.1. strikes, lock-outs or other industrial action;
        </p>
        <p>
            15.2.2. civil commotion, riot, invasion, terrorist attack or threat of terrorist attack, war (whether declared or not) or threat or preparation for war;
        </p>
        <p>
            15.2.3. fire, explosion, storm, flood, earthquake, subsidence, epidemic or other natural disaster;
        </p>
        <p>
            15.2.4. impossibility of the use of railways, shipping, aircraft, motor transport or other means of public or private transport;
        </p>
        <p>
            15.2.5. impossibility of the use of public or private telecommunications networks; and
        </p>
        <p>
            15.2.6. the acts, decrees, legislation, regulations or restrictions of any government.
        </p>
        <p>
            15.3. Our performance under these Website Terms is deemed to be suspended for the period that any Force Majeure Event continues, and we will have an extension of time for performance for the duration of that period. We will use our
            reasonable endeavours to bring any Force Majeure Event to a close or to find a solution by which our obligations under these Website Terms may be performed despite the Force Majeure Event.
        </p>
        <p>
            <strong>16. ADDITIONAL TERMS</strong>
        </p>
        <p>
            <strong>16.1. Privacy Policy:</strong>
            We are committed to protecting your privacy and security. All personal data that we collect from you will be processed in accordance with our Privacy Policy. You should review our
            <a style={{textDecoration:"none", color:'#d10101'}} href="#SUPERSHOPLY_PRIVACY_POLICY">Privacy Policy</a>, which is incorporated into these Website Terms by this reference and is
            <a style={{textDecoration:"none", color:'#d10101'}} href="#SUPERSHOPLY_PRIVACY_POLICY">available here</a>.
        </p>
        <p>
            <strong>16.2. Other terms:</strong>
            You should also review our <a style={{textDecoration:"none", color:'#d10101'}} href="#">Cookies Policy</a> for information regarding how and why we use cookies to improve the quality of the Website and Service, our
            <a style={{textDecoration:"none", color:'#d10101'}}>Voucher Terms and Conditions</a> for information regarding the use of credits and promotional discounts on the Website, and our
            <a style={{textDecoration:"none", color:'#d10101'}} href="#SUPERSHOPLY_COMPETITIONS_TERMS_CONDITIONS">Competitions Terms and Conditions</a> for information regarding the terms applicable to competitions that we may run from time to
            time. All of these are incorporated into these Website Terms by this reference.
        </p>
        <p>
            <strong>16.3. Severability:</strong>
            If any of these Website Terms are determined by any competent authority to be invalid, unlawful or unenforceable to any extent, such term, condition or provision will to that extent be severed from the remaining terms, conditions
            and provisions which will continue to be valid to the fullest extent permitted by law.
        </p>
        <p>
            <strong>16.4. Entire agreement:</strong>
            These Website Terms and any document expressly referred to in them constitute the whole agreement between you and us and supersede all previous discussions, correspondence, negotiations, previous arrangement, understanding or
            agreement between us relating to the subject matter of any contract.
        </p>
        <p>
            <strong>16.5. No waiver:</strong>
            Any failure or delay by you or us in enforcing (in whole or in part) any provision of these Website Terms will not be interpreted as a waiver of your or our rights or remedies.
        </p>
        <p>
            <strong>16.6. Assignment:</strong>
            You may not transfer any of your rights or obligations under these Website Terms without our prior written consent. We may transfer any of our rights or obligations under these Website Terms without your prior written consent to any
            of our affiliates or any business that we enter into a joint venture with, purchase or are sold to.
        </p>
        <p>
            <strong>16.7. Headings:</strong>
            The headings in these Website Terms are included for convenience only and shall not affect their interpretation.
        </p>
        <p>
            <strong>17. GOVERNING LAW AND JURISDICTION</strong>
        </p>
        <p>
            17.1. These Website Terms shall be governed by and construed in accordance with English law. Disputes or claims arising in connection with these Website Terms (including non-contractual disputes or claims) shall be subject to the
            exclusive jurisdiction of the English courts.
        </p>
        <p>
            <strong><a name="SUPERSHOPLY_PRIVACY_POLICY"></a>II. SUPERSHOPLY PRIVACY POLICY</strong>
        </p>
        <p>
            We are committed to protecting the privacy of all visitors to the Website, including all visitors who access the Website or Service through any mobile application or other platform or device. Please read the following Privacy Policy
            which explains how we use and protect your information.
        </p>
        <p>
            We are registered with the Information Commissioner's Office (no. Z9368102) under the Data Protection Act 1998. By visiting and/or using the Service on the Website, you agree and where required you consent to the collection, use and
            transfer of your information as set out in this policy.
        </p>
        <p>
            <strong>1. INFORMATION THAT WE COLLECT FROM YOU</strong>
        </p>
        <p>
            1.1. When you visit the Website or use the Service to make an Order from a Hotel through the Website, you may be asked to provide information about yourself including your name, contact details (such as telephone and mobile numbers
            and e-mail address) and payment information (such as credit or debit card information). We may also collect information about your usage of the Website and Service and information about you from the materials (such as messages and
            reviews) you post to the Website and the e-mails or letters you send to us.
        </p>
        <p>
            1.2. By accessing SUPERSHOPLY information and/or the Website or Service using mobile digital routes such as (but not limited to) mobile, tablet or other devices/technology including mobile applications, then you should expect that
            our data collection and usage as set out in this Privacy Policy will apply in that context too. We may collect technical information from your mobile device or your use of the Website or the Service through a mobile device, for
            example, location data and certain characteristics of, and performance data about, your device, carrier/operating system including device and connection type, IP address, mobile payment methods, interaction with other retail
            technology such as use of NFC Tags, QR Codes or use of mobile vouchers. Unless you have elected to remain anonymous through your device and/or platform settings, this information may be collected and used by us automatically if you
            use the Website or Service through your mobile device(s) via any SUPERSHOPLY mobile application, through your mobile's browser or otherwise.
        </p>
        <p>
            <strong>2. USE OF YOUR INFORMATION</strong>
        </p>
        <p>
            2.1. Your information will enable us to provide you with access to the relevant parts of the Website and to supply the Service. It will also enable us to bill you and enable us and/or a Hotel with whom you have placed an Order to
            contact you where necessary concerning the Service. For example, we and/or the Hotel may use your information to provide you with status updates or other information regarding your Order by e-mail, telephone, mobile or mobile
            messaging (e.g. SMS, MMS etc.). We will also use and analyse the information we collect so that we can administer, support, improve and develop our business, for any other purpose whether statistical or analytical and to help us
            prevent fraud. Where appropriate, now and in the future you may have the ability to express your preferences around the use of your data as set out in this Privacy Policy and this may be exercised though your chosen method of using
            the Service, for example mobile, mobile applications or any representation of the Website.
        </p>
        <p>
            2.2. We may use your information to contact you for your views on the Service and to notify you occasionally about important changes or developments to the Website or the Service.
        </p>
        <p>
            2.3. Where you have indicated accordingly, you agree that we may use your information to let you know about our other products and services that may be of interest to you including services that may be the subject of direct
            marketing and we may contact you to do so by post, telephone, mobile messaging (e.g. SMS, MMS etc.) as well as by e-mail.
        </p>
        <p>
            2.4. Where you have indicated accordingly, you agree that we may also share information with third parties (including those in the food, drink, leisure, marketing and advertising sectors) to use your information in order to let you
            know about goods and services which may be of interest to you (by post, telephone, mobile messaging (e.g. SMS, MMS etc.) and/or e-mail) and to help us analyse the information we collect so that we can administer, support, improve
            and develop our business and services to you.
        </p>
        <p>
            2.5. If you do not want us to use your data in this way or change your mind about being contacted in the future, please let us know by using the contact details set out in paragraph 7 below and/or amending your profile accordingly.
        </p>
        <p>
            2.6. Please note that by submitting Reviews regarding the Website, Service and/or Hotels, you consent to us to use such Reviews on the Website and in any marketing or advertising materials. We will only identify you for this purpose
            by your first name and the city in which you reside (and any other information that you may from time to time consent to us disclosing).
        </p>
        <p>
            <strong>3. DISCLOSURE OF YOUR INFORMATION</strong>
        </p>
        <p>
            3.1. The information you provide to us will be transferred to and stored on our servers which may be in or outside the European Economic Area, and may be accessed by or given to our staff working outside the United Kingdom and third
            parties including companies within the SUPERSHOPLY group of companies (which means our subsidiaries and affiliates, our ultimate holding company and its subsidiaries and affiliates) who act for us for the purposes set out in this
            policy or for other purposes notified to you from time to time in this policy. Countries outside the European Economic Area do not always have strong data protection laws. However, we will always take steps to ensure that your
            information is treated in accordance with this policy.
        </p>
        <p>
            3.2. The third parties with whom we share your information may undertake various activities such as processing credit card payments and providing support services for us. In addition, we may need to provide your information to any
            Hotels that you have placed an Order with so as to allow the Hotel to process and deliver your Order. By submitting your personal data, you agree to this transfer, storing or processing. We will take all steps reasonably necessary
            to ensure that your data is treated securely and in accordance with this Privacy Policy.
        </p>
        <p>
            3.3. If you have consented we may allow carefully selected third parties, including marketing and advertising companies, our affiliates and associates, to contact you occasionally about services that may be of interest to you. They
            may contact you by post, telephone, mobile messaging (e.g. SMS, MMS, etc.) as well as by e-mail. If you change your mind about being contacted by these companies in the future, please let us know by using the contact details set out
            in paragraph 7 below and/or by amending your profile accordingly.
        </p>
        <p>
            3.4. If our business enters into a joint venture with, purchases or is sold to or merged with another business entity, your information may be disclosed or transferred to the target company, our new business partners or owners or
            their advisors.
        </p>
        <p>
            3.5. We may use the information that you provide to us if we are under a duty to disclose or share your information in order to comply with (and/or where we believe we are under a duty to comply with) any legal obligation; or in
            order to enforce the Website Terms and any other agreement; or to protect our rights or the rights of Hotels or other third parties. This includes exchanging information with other companies and other organisations for the purposes
            of fraud protection and prevention.
        </p>
        <p>
            <strong>4. SECURITY AND DATA RETENTION</strong>
        </p>
        <p>
            4.1. We take steps to protect your information from unauthorised access and against unlawful processing, accidental loss, destruction and damage. We will keep your information for a reasonable period or as long as the law requires.
        </p>
        <p>
            4.2. Where you have chosen a password which allows you to access certain parts of the Website, you are responsible for keeping this password confidential. We advise you not to share your password with anyone. Unless we negligently
            disclose your password to a third party, we will not be liable for any unauthorised transactions entered into using your name and password.
        </p>
        <p>
            4.3. The transmission of information via the internet is not completely secure. Although we will take steps to protect your information, we cannot guarantee the security of your data transmitted to the Website; any transmission is
            at your own risk. Once we have received your information, we will use strict procedures and security features to try to prevent unauthorised access.
        </p>
        <p>
            <strong>5. ACCESSING AND UPDATING</strong>
        </p>
        <p>
            5.1. You have the right to see the information we hold about you (<strong>"Access Request"</strong>) and to ask us to make any changes to ensure that it is accurate and up to date. If you wish to do this, please contact us using the
            contact details set out in paragraph 7 below. In the event that you make an Access Request, we reserve the right to charge a fee of ten pounds (£10.00) to meet our costs in providing you with details of the information we hold about
            you.
        </p>
        <p>
            <strong>6. CHANGES TO OUR PRIVACY POLICY</strong>
        </p>
        <p>
            6.1. Any changes to our Privacy Policy will be posted to the Website and, where appropriate, through e-mail notification.
        </p>
        <p>
            <strong>7. CONTACT</strong>
        </p>
        <p>
            7.1. All comments, queries and requests relating to our use of your information are welcomed and should be addressed to Supershoply Limited at Reception Unit A Aston Seedbed Centre, Avenue Road, Birmingham, England, B7 4NT Tel 07862202343.
        </p>
        <div class="referral-faq-wrap" id='COOKIES_POLICY'>
        <p style={{fontSize:"18px", fontWeight:"bold"}}><a name="SUPERSHOPLY_PRIVACY_POLICY"></a>III. COOKIES POLICY</p>
          
      <div ><p>Updated 15 May 2023</p></div>
             <div >
    <p >We use cookies to improve the quality of our site and service, and to try and make your browsing experience meaningful.</p>
    <p >When you enter our site our web server sends a cookie to your computer which allows us to recognise your computer but not specifically who is using it. By associating the identification numbers in the cookies with other customer information when for example you log-in to the site, then we know that the cookie information relates to you.</p>
    <p >By proceeding beyond this page you consent to our cookie settings and agree that you understand this Cookies Policy which explains how you can manage your cookie choices and preferences.</p>
    <p ><strong>What are cookies?</strong></p>
    <p >Cookies are small pieces of information which are issued to your computer when you visit a website and which store and sometimes track information about your use of the site. A number of cookies we use last only for the duration of your web session and expire when you close your browser. Other cookies are only used where you ask us to remember your login details for when you next return to the site and will last for a longer duration.<br/>
    <strong>Why do we use cookies?</strong></p>
    <p >The cookies used on www.Supershoply.com are explained below and based on the International Chamber of Commerce guide for cookie categories.</p>
    <p ><strong>1. Strictly necessary cookies</strong></p>
    <p >Strictly necessary cookies allow you to use essential features of our site such as enabling you to order a restaurant more easily.</p>
    <p >The essential features of our site require strictly necessary cookies so that we can remember your area and what is in your shopping basket whilst you continue to order products from our site and/or navigate to different pages of the site during your browsing session. We are also able to identify you as being logged in to www.Supershoply.com and to ensure that you are able to access the appropriate features on our site.</p>
    <p ><strong>2. Performance cookies</strong></p>
    <p >Performance cookies collect information about how you use our site, so that we can improve the quality of our site and service.</p>
    <p >Our performance cookies do not collect any information that could potentially identify you. All information collected is anonymous and is used by us to:</p>
    <strong class="blog-ul">
    <p>provide statistics on how our site is used;</p>
    <p>monitor which website our users arrived from; and</p>
    <p>help us improve the site by measuring usage information and any errors which occur during our users browsing experience.</p>
    </strong>
    <p ><strong>3. Functionality cookies</strong></p>
    <p >Functionality cookies provide you with improved site functionality by allowing access to additional services or to enhance your future visits to our site.</p>
    <p >Functionality cookies used on our site provide you with improved site functionality whilst you are browsing for example, giving you access to our online help service with our live chat team if you require help and assistance with your order processing. The information shared is only to be used to provide the service, product or function and not for any other purpose.</p>
    <p ><strong>4. Targeting &amp; Sharing cookies</strong></p>
    <p >Cookies which customise your browsing experience on our site only collect information by reference to the IP address that you are using. Some of these customised browsing experiences may be linked to services provided by third parties which provide these services for recognising that you have visited our site. This information is used to inform you of products and/or services which may be of interest to you or for services provided by our third party partners.</p>
    <p >These cookies may also link to social networks such as Facebook or Twitter provide advertising agencies with information on your visit so that they can present you with adverts which may be of interest to you.</p>
    <p ><strong>Managing your cookie preferences</strong></p>
    <p >Some of the cookies used by our site are served by us, and some are served by third parties who are delivering services on our behalf. Most web browsers automatically accept cookies but, if you prefer, you can change your browser to prevent that or to notify you each time a cookie is set. You can also learn more about cookies by visiting <a href="http://www.allaboutcookies.org/">www.allaboutcookies.org</a> which includes additional useful information on cookies and how to block cookies using different types of browser. Please note however, that by blocking or deleting cookies used on our site you may not be able to take full advantage of our site if you do so.</p>
    <p >We are continually striving to develop improved ways of managing your cookie preferences. As new technologies and solutions emerge, this cookies policy may be updated to reflect any such advances in technology and preference management tools.</p>
    </div>
      </div>
    </div>
    
          </div>
        </div>
  )
}

export default TermCondition