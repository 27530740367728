const Labels = {
    Home:"Home",
    Pricing:"Pricing",
    ContactInfo:"Contact Information",
    Terms_Condition:"Terms and Conditions",
    Return_Refund_policy:"Returns & refunds policy",
    Delivery_policy:"Delivery policy",
    Privacy_policy:"Privacy policy",
    FAQs:"FAQs",
    Supershoply:"Create a simple and beautiful online store within minutes.",
    Basic:"Basic",
    Free_Trial:"Start free trial",
    Company_Address:"Supershoply Limited",
    Registeration_number:"Registration No:",
    Registeration_numeric:"14484561",
    Contatact_numeric:"+44 330 113 0070",
    Contatact_Email:"info@supershoply.com",
    Monthly:"Monthly",
    Yearly:"Yearly",
    Subscribe:"Try for free",
    Close_Plan:"Close plan features",
    Show_Plan:"Show plan features",
    Pay_Monthly:"Pay Monthly",
    Pay_Yearly:"Pay Yearly",
    Features:"Features",
    
}

export default Labels;