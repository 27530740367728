import './App.scss';
// import { Link, Routes,Route} from "react-router-dom";
import React, { Component, useEffect } from 'react'
import Navbar from './Components/Navbar'
import Footer from './Components/footer'

//links section
import Subscription from "./pages/Subscription";
import Home from "./pages/Home";
import TermCondition from './pages/TermsCondition';
import {Routes,Route,BrowserRouter  } from "react-router-dom";


function App() {
  
    return (
    <div>
        <BrowserRouter>
        <div className='banner-main-wrap'>
        <Navbar/>
        {/* <Off/> */}
        <Routes>
      <Route path="/" exact element={<Home />}/>
      <Route path="subscription" element={<Subscription />} />
      <Route path="home" element={<Home />} />
      <Route path="/termcondition" element={<TermCondition />} />
    </Routes>
    </div>
     <Footer/> 
    {/* {location.pathname !== '/review' && <Footer />} */}
    </BrowserRouter>
     </div>
    
    )
}
export default App;

