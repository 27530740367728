import React,{useEffect} from 'react'
import { useLocation } from 'react-router-dom';
import Messages from '../language/ConsumerMessages';
import Labels from '../language/labels';

function Footer() {
    let location = useLocation();
  
    useEffect(() => {
      // Google Analytics
  console.log(location.pathname)
    }, [location]);
    
  return (
    <div className={`footer ${location.pathname==="/Home" || location.pathname==="/"? 'footer-home':"footer-price"}`}>
    <footer className={`container text-lg-start footer-main-wrap ${location.pathname==="/Home" || location.pathname==="/"? 'footer':"footer1 mt-5"}`}>

    <section className="d-flex justify-content-center justify-content-lg-between">
         
    </section>
    <section className="">
        <div className=" text-center text-md-start footer-info-wrap">

            <div className="mt-5 d-flex justify-content-between footer-right-wrap">
            
                <div className="col-md-3 col-lg-4 col-xl-4  mb-4 text-left px-0">
                 
                    <h6 className=" fw-bold mb-3 font-30">
                       {Labels.Company_Address}
                    </h6>
                    <div className="d-flex flex-column">
                   
                    <span className="mb-2 d-flex flex-column">
                       
                    <span className="font-18">{Messages.Company_Address_Msg} </span></span>
                    <span className="mb-2 d-flex  align-items-baseline  "> <span className="font-18 d-flex mr-1">{Labels.Registeration_number} </span> <span className="font-16">  {Labels.Registeration_numeric} </span></span>
                </div>
                </div>
                <div className="col-md-3 col-lg-4 col-xl-3  mb-4 text-left inner">
                  
                    <h6 className=" fw-bold mb-3 font-18">
                       {Labels.ContactInfo}
                    </h6>
                    <div className="d-flex flex-column">
                    
                    <span className="mb-2"> <i className="fas fa-phone mr-4 font-14"></i> <span className="font-14"> <a className="text-dark" href="tel:+443301130070">{Labels.Contatact_numeric}</a></span></span>
                    <span className="mb-2"> <i className="fas fa-envelope mr-4 font-14"></i> <span className="font-14 "><a className="text-dark" href="mailto:info@supershoply.com"> {Labels.Contatact_Email} </a></span> </span>
                   
                </div>
                </div>
                <div className="col-md-2 col-lg-2 col-xl-2 mb-4 text-left inner">
                    
                    <h6 className=" fw-bold mb-3 font-18">
                        {Labels.Terms_Condition}
                    </h6>
                    <div className="d-flex flex-column privacy-policy-links">
                    <span className="mb-2"> <a href="#!" className="text-dark font-14 ">{Labels.Return_Refund_policy}</a> </span>
                    <span className="mb-2"> <a href="#!" className="text-dark font-14">{Labels.Delivery_policy}</a> </span>
                    <span className="mb-2"><a href="#!" className="text-dark font-14 ">{Labels.Privacy_policy}</a></span>
                    <span className="mb-2"><a href="#!" className="text-dark font-14 ">{Labels.FAQs}</a></span>
                </div>
                </div>
              
            </div>
        </div>
    </section>
   
    <div className="copyright-wrap px-0 col-xl-12 col-sm-4 col-md-12 col-lg-12 d-flex align-items-center flex-column flex-md-row justify-content-between ">
       <span className="mb-3 rights-wrap">  {Messages.Copyright_Msg} <a href="/termcondition" target="_blank">Terms & Conditions | Privacy Policy</a></span>
       
        {/* <div className="credit-cards mb-3 " >
            <img className="mr-2" height="40" src="https://shoplineimg.com/assets/footer/card_visa.png"/>
            <img className="mr-2" height="40" src="https://shoplineimg.com/assets/footer/card_master.png"/>
            <img className="mr-2" height="40" src="https://shoplineimg.com/assets/footer/card_paypal.png"/>
           
          </div> */}
         
    </div>
    
</footer>
</div>
  )

}
export default Footer

