import React from 'react'
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { FiPlus,FiMinus } from "react-icons/fi";
import { FiChevronDown,FiChevronRight } from "react-icons/fi";
import Messages from '../language/ConsumerMessages';
import Labels from '../language/labels';
import * as Utilities from '../helpers/Utilities';
import Shoplysubscriptions from '../Shoplysubscriptions.json';
import { FormGroup, Label, Button, Modal, ModalBody, ModalFooter, ModalHeader, Table, Alert } from 'reactstrap';
import ScrollToTop from "react-scroll-to-top";


import * as CountryService from '../services/Country';

const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1000 },
      items: 4.01,
      slidesToSlide: 1
       // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1000, min: 480 },
      items: 2.2,
      slidesToSlide: 1 // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 480, min: 0 },
      items: 1.1,
      slidesToSlide: 1 // optional, default to 1.
    }
  };


 
  class Subscription extends React.Component { 
    constructor(props) {
        super(props);
        
        this.state = {
            slide:false,
            slideMobile:false,
            activep: 1,
            scrolled: false,
            showSubscription: false,
            active: false,
            SubscriptionInfo: false,
            activeNumber: 0,
            SubscriptionPriceEdit: false,
            status: false,
            monthlyFee: '',
            title: '',
            price: '',
            yearlyDiscount: '',
            trialDays: '',
            features: [],
            isSaving: false,
            disabled: false,
            subscriptionPlans: [],
            disabled: true,
            isPlanExist: -1,
            planErrorMessage: "",
            subscriptionPlans: [],
            showLoader: true,
            EnterpriseRRM: false,
        }

        this.getCurrentCountry();
    
      }

      getCurrentCountry = async () => {
        try {
            let response = await CountryService.getCurrentCountry()
            if (Object.keys(response).length > 0) {
               
                
                var subscriptionPlans = Shoplysubscriptions.find((s) => {
                    return s.Name.toLowerCase() == response.ip.country.toLowerCase();
                })

               

                this.setState({
                  currentCountry: response.ip,
                  subscriptionPlans: subscriptionPlans
                  
                })
                
                this.setState({showLoader: false})
            }
            
         
        } catch (error) {
          
        }
    }
    EnterpriseRRMModal =()=> {
        this.setState({
            EnterpriseRRM: !this.state.EnterpriseRRM,
        })
    }

        subScriptionPlanHtml= () => {
         const {subscriptionPlans} = this.state;
         var plans = subscriptionPlans.SubscriptionDetail;
            
        return(<div className='subscription-p-wrap carousel-container'> 
      
      {plans.map((plan, index) => {
       
        return (
            
            <div className={`${this.state.activeNumber == index ?'pricing-card-f flex-column justify-content-start align-items-center d-flex  ':'d-lg-flex d-none pricing-card-f flex-column justify-content-start align-items-center'} ${plan.IsHighlighted? "enterprise-color" : ""}`}>
            <div className='pricing-content d-flex flex-column justify-content-between align-items-center mb-5'>
              <h4 className='mb-3'>{plan.Name}</h4>
              <span className='font-16'>{plan.Description}</span>
            </div>
           
          
            <div className={`d-flex mb-2 line-through-price ${this.state.activep == 1 && 'invisible'}`}>
            <span>{subscriptionPlans.CurrencySybmol}</span>
            <span>{Utilities.FormatCurrency(plan.MonthlyPrice,subscriptionPlans.DecimalPlaces)}</span>
             </div>

            <div className='actual-price d-flex justify-content-center mb-2'>
              <span>{subscriptionPlans.CurrencySybmol}</span>
              <span>{this.state.activep == 1 ? Utilities.FormatCurrency(plan.MonthlyPrice,subscriptionPlans.DecimalPlaces) : Utilities.FormatCurrency(plan.YearlyPrice/12,subscriptionPlans.DecimalPlaces)}</span>
              <div className='d-flex align-items-end'><span>/mo</span></div>
            </div>
            <div className=' font-14 text-center'>
              <span>{this.state.activep == 1 ? "For a month" : "Billed yearly"}</span>
            </div>
            
           
            <hr className='price-divide' />
            <div className='pricing-info-section mt-3 w-100 d-flex flex-column h-100'>
              <h5>{plan.IsHighlighted ? "All features in Advance plus" : "What is included on"} {plan.Name}</h5>
              
              {!Utilities.stringIsEmpty(plan.FeaturesIncluded) &&
              <ul className='mb-3 pl-0'>
              
              {plan.FeaturesIncluded.split('|').map((featureInclude) => {
                return(
                  <li className='mb-2'>
                  <span className='font-16'>{featureInclude}</span>
                </li>)
                })} 
              </ul>
                }
              
            <a href={this.state.activep == 1 ? plan.PaymentLinkMonthly : plan.PaymentLinkYearly}  className={`large-btn btn btn-outline-primary btn-lg btn-block mt-auto resp-btn d-flex align-items-center justify-content-center`}>{Labels.Subscribe}</a>
            </div>
          
          </div>
    
        )
      })}
         
      </div>)
      }
    
      toggleSlide = (i) => {

         this.setState({
            slide: !this.state.slide
          })
      }
      toggleSlideMobile = (i) => {

             this.setState({
                slideMobile: !this.state.slideMobile
              })
          }
     showPackageOld = (i) => {
        //  setActive(i)
        this.setState({
            activep:i
        })
        }
        showPackage = (number) => {
            this.setState({
              activeNumber: number
            })
           
          }

          renderSubscriptionData = (i, feature) => {
            return (
                
                i == -1 ? 

                <td headers="p-0 f-website" className="res-p-show">
                                                    <div className="pricing-table__feature-name">
                                    <div className="feature-label m-0 d-flex flex-column">
                                        <label className="feature-label__text font-weight-normal m-0 font-18">{feature.Name}</label>
                                        <label className="feature-label__text font-weight-normal m-0 font-14 color-7">{feature.Description}.</label>
                                    </div>
                                </div>
                </td>
               
               : 

               
                <td headers="p-0 f-website" className="res-p-show">
                <div className="text-center">
                    <span className="pricing-table__feature-value text-center">
                        { feature.DataType.toLowerCase() == "bool" ? 
                        
                        (feature.Value == "1" ? <i className='fa fa-check fa-w-16 text-success'></i> : <i className='fa fa-times fa-w-16'></i>)
                        
                        :

                        (feature.Value == "-1" ? "Unlimited" : feature.Value)
                    }
                    </span>
                </div>
            </td>

            )
          }
        
          renderSubscriptionRows = (plan, fIndex, isResponsive) => {
        
            var subsHtml = [];
            
            if(isResponsive){
           
                subsHtml.push(this.renderSubscriptionData(-1, plan.SubscriptionFeatureList[fIndex]))
                const value = plan.SubscriptionFeatureList[fIndex];
                subsHtml.push(this.renderSubscriptionData(0, value))

            } else {
                subsHtml.push(this.renderSubscriptionData(-1, plan[0].SubscriptionFeatureList[fIndex]))
            for (let i = 0; i < plan.length; i++) {
                if(!plan[i].IsHighlighted) {
               const value = plan[i].SubscriptionFeatureList[fIndex];
              subsHtml.push(this.renderSubscriptionData(i, value))
                }
            }
            }
            return (<tr>{subsHtml.map((submenuHtml) => submenuHtml)}</tr>)
        
          }


    render() {

        const {subscriptionPlans} = this.state
        if(this.state.showLoader) return <div></div>;


  return (
    <div className='container container-inner'>
        <div className='price-heading-section'>
        <h1 className='font-weight-bold text-center pb-4'>{Messages.Setup_Store}</h1>
        <p className='price-para text-center mb-5'>{Messages.Try_Supershoply_Days}</p>
        </div>
       
          <div className=" d-flex align-items-center justify-content-center mb-5 mt-4">
              <div className="subscription-btns medium-btn packages">
              <button type="button" className={this.state.activep === 1 ?"btn  btn-package mr-2 active":"btn btn-package mr-2"} onClick={()=>this.showPackageOld(1)} >{Labels.Monthly}</button>
                <button type="button" className={this.state.activep === 2 ?"btn  btn-package active":"btn  btn-package"} onClick={()=>this.showPackageOld(2)}>{Labels.Yearly} (Save 25%)</button>
              </div>
          </div>
          <div className='subscription-tabs d-lg-none d-xl-none '>
                      <ul class="nav nav-tabs">

                        <li class="nav-item flex-1 position-relative">
                          <a class={this.state.activeNumber == 0 ? "nav-link border-0 active" : "nav-link border-0"} aria-current="page" onClick={() => this.showPackage(0)}>
                            <span > {subscriptionPlans.SubscriptionDetail[0].Name}</span>
                            
                          </a>
                        </li>
                        <li class="nav-item flex-1 position-relative">
                          <a class={this.state.activeNumber == 1 ? "nav-link active border-0" : "nav-link border-0"} onClick={() => this.showPackage(1)}>
                            <div className=''>
                              <span> {subscriptionPlans.SubscriptionDetail[1].Name}</span>
                            </div>

                            
                          </a>
                        </li>
                        <li class="nav-item flex-1 position-relative">
                          <a class={this.state.activeNumber == 2 ? "nav-link active border-0" : "nav-link border-0"} onClick={() => this.showPackage(2)}>

                            <span>{subscriptionPlans.SubscriptionDetail[2].Name}</span>
                           
                          </a>
                        </li>
                      </ul>
                    </div>
        {this.subScriptionPlanHtml()}
        
        <div onClick={()=> this.toggleSlide()} className="hide-mobile compare-plan-button d-flex  align-items-center justify-content-center card-body p-5">
            <span className="large-btn btn btn-outline-secondary cursor-pointer d-flex font-13 mr-3 align-items-center">
            <span id="hide"> {this.state.slide?<div><FiMinus size="1.2em"/><span className='ml-2'> {Labels.Close_Plan} </span></div>:<div><FiPlus size="1.2em"/><span className='ml-2'>{Labels.Show_Plan}</span></div>} </span>
             </span>
          </div>
          <div onClick={()=> this.toggleSlideMobile()} className="hide-web w-100 mb-5">
         
             {this.state.slideMobile?<div className='compare-btn d-flex justify-content-between align-items-center'><span >{Labels.Close_Plan}</span> <FiChevronDown size="1.2em"/></div>:<div className='compare-btn d-flex justify-content-between align-items-center'><span>{Labels.Show_Plan}</span> <FiChevronRight size="1.2em"/></div>} 
           
          </div>
        <div className={this.state.slide?'subscription-wrap subscription-wrap-m hide-mobile ':' hide-mobile d-none subscription-wrap subscription-wrap-m'}>
        <div className='subscription-items pricing-table__wrapper card-body p-4'>
        <table className="marketing-table pricing-table w-100" id="PricingTable">
                    <thead>
                        <tr className="res-d-none-md">
                            <th className="border-0 invisible" style={{width:"650px"}}><span>Plan feature</span></th>
                            
                            {subscriptionPlans.SubscriptionDetail.map((subscription) => {

                            return (
                                !subscription.IsHighlighted ? 
                                <th className="tbl-head-c">
                                <div className="d-flex justify-content-center flex-column text-center">
                                    <h4 className=" position-relative">
                                        <span>{subscription.Name}</span> 
                                    </h4>
                                    <div>
                                         <span className="d-flex justify-content-center font-weight-normal align-items-baseline">
                                            <span className="actual-price font-22 bold">{subscriptionPlans.CurrencySybmol}{Utilities.FormatCurrency(subscription.MonthlyPrice,subscriptionPlans.DecimalPlaces)}</span><span className="font-12 color-7 ml-1">/Monthly</span>
                                            
                                        </span> 
                                    </div>
                                    <div>
                                         <span className="d-flex justify-content-center font-weight-normal align-items-baseline">
                                           <span className="actual-price font-22 bold">{subscriptionPlans.CurrencySybmol}{Utilities.FormatCurrency(subscription.YearlyPrice,subscriptionPlans.DecimalPlaces)}</span><span className="font-12 color-7 ml-1">/Yearly</span>
                                        </span> 
                                    </div>
                                </div>
                            </th>
                            : <></>
                            )
                            }) 
    }
    
                        </tr>
                    </thead>
                    <tbody className="pricing-table__table-body">
                      <tr>
                        <th className="feature-h" colspan="5" style={{backgroundColor:"#F1F8F5"}}><span className="font-18 bold">{Labels.Pricing}</span></th>
                    </tr>
                   
                    <tr>
                    <th className="feature-h"><span className="font-18 bold">{Labels.Pay_Monthly}</span></th>
                      {subscriptionPlans.SubscriptionDetail.map((subscription) => {

                    return ( 
                    
                        !subscription.IsHighlighted ? 
                       
                        <th className="feature-h text-center"><span className="font-18 bold">{subscriptionPlans.CurrencySybmol}{Utilities.FormatCurrency(subscription.MonthlyPrice,subscriptionPlans.DecimalPlaces)}/mo</span></th>
                        : <></>
                    
                    
                        )
                    }) 
                    }
                    </tr>
                        <tr>
                        <th className="feature-h"><span className="font-18 bold">{Labels.Pay_Yearly}</span></th>
                        {subscriptionPlans.SubscriptionDetail.map((subscription) => {

                        return ( 
                            !subscription.IsHighlighted ? 

                        <th className="feature-h text-center"><span className="font-18 bold">{subscriptionPlans.CurrencySybmol}{Utilities.FormatCurrency((subscription.YearlyPrice/12),2)}/mo</span></th>
                        : <></>

                            )
                        }) 
                        }
                        </tr>
                                        
                        <tr>
                            <th className="feature-h " style={{backgroundColor:"#F1F8F5"}} colspan="5"><span className="font-18 bold">{Labels.Features}</span></th>
                        </tr>
                        {
                              this.state.subscriptionPlans.SubscriptionDetail.length > 0 && this.state.subscriptionPlans.SubscriptionDetail[0].SubscriptionFeatureList
                              .map((v, index) => (
                                this.renderSubscriptionRows(this.state.subscriptionPlans.SubscriptionDetail, index, false)
                              ))
                        }
                    </tbody>
                </table>
                </div>
                <div className='enterprise-box-heading my-4 justify-content-center d-none d-lg-flex d-xl-flex'>
                    <h2>Alternative solutions for your Enterprise</h2>
                    </div>
                <div className='enterprise-box-wrap-web  d-none d-lg-flex d-xl-flex'>
                    <div className='d-flex'>
                    <div className='logo-heading-wrap w-50'>
                    <div className="d-flex align-items-center mb-4">
                  <span href="/Home" className="navbar-brand d-flex align-items-center">
                      <img src="https://cdn.superme.al/s/shoply/images/logo-square.png" width="auto" height="45" alt="" className="navbar-brand-full" />
                      <a className="ml-2 logo-text text-white">Supershoply</a></span>
              </div>
                <h3 className="mb-4">Enterprise</h3>
                <h4 className='mb-2'>Starting at $2,000 USD/month</h4>
                <p className='mb-2'>All-in-one solution for high-volume businesses</p>
                </div>
                <div className='enterprise-button-p w-50 d-flex justify-content-center'>
                <button type='button' className='mt-5 large-btn btn'onClick={() => this.EnterpriseRRMModal()}>Let`s talk</button>
                </div>
                </div>
                </div>
                </div>
            
               

                <div className={`subscription-wrap subscription-wrap-m hide-web ${!this.state.slideMobile && 'd-none'}`}>
        <div className='subscription-items pricing-table__wrapper my-5'>
        <table className="marketing-table pricing-table w-100" id="PricingTable">
                  
                    <tbody className="pricing-table__table-body">
                      <tr>
                        <th className="feature-h" colspan="5" style={{backgroundColor:"#F1F8F5"}}><span className=" bold">{Labels.Pricing}</span></th>
                    </tr>
                    <tr>
                      <th className="feature-h"><span className="bold">{Labels.Pay_Monthly}</span></th>
                      <th className="feature-h text-center" style={{width:100}}><span className=" bold">{subscriptionPlans.CurrencySybmol}{Utilities.FormatCurrency(subscriptionPlans.SubscriptionDetail[this.state.activeNumber].MonthlyPrice,subscriptionPlans.DecimalPlaces)}/mo</span></th>
                      
                  </tr>
                  <tr>
                    <th className="feature-h"><span className="bold">{Labels.Pay_Yearly}</span></th>
                    <th className="feature-h text-center"><span className=" bold">{subscriptionPlans.CurrencySybmol}{Utilities.FormatCurrency(subscriptionPlans.SubscriptionDetail[this.state.activeNumber].YearlyPrice/12,subscriptionPlans.DecimalPlaces)}/mo</span></th>
                </tr>
                        <tr>
                            <th className="feature-h " style={{backgroundColor:"#F1F8F5"}} colspan="5"><span className=" bold">{Labels.Features}</span></th>
                        </tr>
                       
                        {
                              this.state.subscriptionPlans.SubscriptionDetail.length > 0 && this.state.subscriptionPlans.SubscriptionDetail[0].SubscriptionFeatureList
                              .map((v, index) => (
                                this.renderSubscriptionRows(this.state.subscriptionPlans.SubscriptionDetail[this.state.activeNumber], index, true)
                              ))
                        }


                      
                    </tbody>
                </table>
                </div>
                </div>
                <div className='enterprise-box-heading mb-4 text-center d-lg-none d-xl-none'>
                    <h3>Alternative solutions for your Enterprise</h3>
                    </div>
                <div className='enterprise-box-wrap d-lg-none d-xl-none'>
                <div className="d-flex align-items-center justify-content-center mb-4">
                  <span href="/Home" className="navbar-brand d-flex align-items-center ">
                      <img src="https://cdn.superme.al/s/shoply/images/logo-square.png" width="auto" height="45" alt="" className="navbar-brand-full" />
                      <a className="ml-2 logo-text text-white">Supershoply</a></span>
              </div>
                <h2 class="mb-5">Enterprise</h2>
                <h3>Starting at $2,000 USD/month</h3>
                <p cl>All-in-one solution for high-volume businesses</p>
                <button type='button' className='mt-5 large-btn btn'onClick={() => this.EnterpriseRRMModal()}>Let`s talk</button>
                </div>
                <ScrollToTop smooth />


                <Modal className='modal-c-wrap' isOpen={this.state.EnterpriseRRM} toggle={() => this.EnterpriseRRMModal()}>
          
          <ModalBody >
          <iframe src="https://rrm.superbutler.app/forms/wtl/1cff13c0d34ca29cce88ae1da257f62b?styled=1&with_logo=0" className="iframe-wrap" style={{width:"100%", border:"none", height:"calc(100vh - 70px)"}} title="Iframe Example"></iframe>
          </ModalBody>
        
        </Modal>

    </div>
    
  )
  
}

  }
 
export default Subscription;