import Shoplysubscriptions from '../Shoplysubscriptions.json';


export let getCurrentCountry = async () => {
  
    try {
        const response = await
            fetch("https://ip.nf/me.json",
                {
                    method: 'GET',
                })
                const result = await response.json();
                return result;
    }
    catch (e) {
      
        return [];
    }
}


export let GetJsonFIle = async () => {

    try {
        let GetjsonPath = Shoplysubscriptions;
        
        const response = await
            fetch(GetjsonPath,
                {
                    cache: 'no-cache',
                    method: 'GET',
                })

        const result = await response.json();
        return result;

    }
    catch (e) {
      
        return {};
    }
}


