
export let stringIsEmpty = (str) => {
    return (!str || /^\s*$/.test(str));
};

export let RemoveDefinedSpecialChars = (e) => {

     let initVal = e.target.value.replace(/"/gi, "").replace(/\\/gi, "");

     if (e.keyCode !== 8) {
         initVal  = initVal +  String.fromCharCode(e.keyCode)
      }
     else{
         initVal = initVal.substr(0, e.target.value.length-1)

      } 

    let specialChars = ";/:^#|`~[]{}<>?";

    for (var i = 0; i < specialChars.length; i++) {
        initVal = initVal.replace(new RegExp("\\" + specialChars[i], 'gi'), "");
     }

    e.target.value = initVal;
   
 }

export let RemoveSpecialChars = (e) => {

    let initVal = e.target.value.replace(/"/gi, "").replace(/\\/gi, "");

    let specialChars = "!@$^&%*()+=[]/{}|:<>?,.*;";

    for (var i = 0; i < specialChars.length; i++) {
        initVal = initVal.replace(new RegExp("\\" + specialChars[i], 'gi'), "");
    }

    e.target.value = initVal;

}


export let FormatCurrency = (number, decimals) => {
    var separator = "."; // Default to period as decimal separator
    number = Number(number);
    decimals = Number(decimals);
    decimals = (decimals === undefined || decimals === null) ? 2 : decimals; // Default to 2 decimals
    var divider = (decimals === 2) ? 100 : 1;

    var value = "";

    if (Number(decimals) === 0) {
        value = number.toLocaleString().split(separator)[0];
    }
    else {

        if (number.toLocaleString().split('.').length > 2) {
            value = (Math.floor(number * (1000)) / divider).toLocaleString();
        }
        else {

            value = number.toLocaleString();
        }
        value = value.toLocaleString().split(separator)[0] + ((value.toLocaleString().split('.').length > 1) ? "." + ((value.split('.')[1].length === 1) ? value.split('.')[1] + "0" : value.split('.')[1].substr(0, 2)) : ".00");
    }

    return value;
}


export let ValidateDecimals = (e) => {

    let value = e.target.value;
    // remove all characters that aren't digit or dot
    value = value.replace(/[^0-9.]/g, '');
    // replace multiple dots with a single dot
    value = value.replace(/\.+/g, '.');
    value = value.replace(/(.*\.{[0-9][0-9][0-9]}?).*/g, '$1');

    // replace multiple zeros with a single one
    value = value.replace(/^0+(.*)$/, '0$1');
    // remove leading zero
    value = value.replace(/^0([^.].*)$/, '$1');

    e.target.value = value;
}


export let FormatCsv = (csv, csvSeperator) => {

    if (csv === "") return csv;

    if (csv.lastIndexOf(csvSeperator) !== -1) {
        csv = csv.substring(0, csv.length - csvSeperator.length);
    }

    return csv;
}

export let GetObjectArrId = (id, arrObject) => {

    var rowId = "-1";
    for (var arrobj = 0, arrlen = arrObject.length; arrobj < arrlen; arrobj++) {
        if (Number(arrObject[arrobj].Id) === Number(id) || Number(arrObject[arrobj].ID) === Number(id) || Number(arrObject[arrobj].id) === Number(id)) {
            rowId = arrobj;
            break;
        }
    }
    return rowId;
}

export let SpecialCharacterDecode = (text) => {

    if (stringIsEmpty(text)) {
        return text;
    }

    text = text.replace(/&#34;/gi, '"');
    text = text.replace(/&#39;/gi, "'");
    text = text.replace(/<br>/gi, "\n");
    text = text.replace(/&#96;/gi, '`');
    text = text.replace(/&#145;/gi, '‘');
    text = text.replace(/&#146;/gi, '’');
    text = text.replace(/gi&#147;/gi, '“');
    text = text.replace(/&#148;/gi, '”');
    text = text.replace(/&lt;/gi, "<");
    text = text.replace(/&gt;/gi, ">");
    text = text.replace(/&#47;/gi, "/");
    text = text.replace(/&#43;/gi, "+");
    text = text.replace(/&amp;#39;/gi, "'");
    text = text.replace(/&amp;/gi, "&");
    text = text.replace(/amp;/gi, "");
    return text;
}


export let SpecialCharacterEncode = (text) => {

    if (stringIsEmpty(text)) {
        return text;
    }
    text = text.replace(/\r?\n|\r/g, "<br>");
    text = text.replace(/"/gi, "&#34;");
    text = text.replace(/'/gi, "&#39;");
    text = text.replace(/`/gi, "&#96;");
    text = text.replace(/‘/gi, "&#145;");
    text = text.replace(/’/gi, "&#146;");
    text = text.replace(/“/gi, "&#147;");
    text = text.replace(/”/gi, "&#148;");
    text = text.replace(/\//gi, "&#47;");
    text = text.replace(/\\/gi, "&#%5C");
    text = text.replace(/</gi, "&lt;");
    text = text.replace(/>/gi, "&gt;");
    text = text.replace(/(?:\\[rn]|[\r\n]+)+/g, "<br>");
    text = text.replace(/\xA0/g, " ");
    text = text.replace(/%x000A/g, ""); //U+000A 
    text = text.replace(/%x000C/g, "");//U+000C

    return text;
}