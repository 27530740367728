import React from 'react'
// import {Link } from "react-router-dom";
import Messages from '../language/ConsumerMessages';
import Labels from '../language/labels';
import home from '../images/landing-page-img-3.png'
// import Footer from '../Components/footer';
function Home() {
  return (
    <div className="container">
    {/* <div className="w3-display-topleft w3-padding-large w3-xlarge d-flex justify-content-between align-items-center w-100">
    
            <a  className="navbar-brand">
                <img src="https://supershoply.com/img/icon.png" width="auto" height={75}  alt="" className="navbar-brand-full"/>
            </a>
    </div> */}
    <div className="mid-section">
        <div className="w3-display-middle">
          <div className='custom-padding'>
            <h1 className="w3-jumbo w3-animate-top">{Labels.Supershoply}</h1>
            <hr className="w3-border-grey my-4 my-sm-5" />
            <p className="w3-large w3-center mb-5">{Messages.Sell_Online}
            {/* <br/>{Messages.Successful_brand} */}
            </p>
            <a className="w-100 custom-button" href="https://register.supershoply.com" target="_blank">
                <span className="sm-button">{Messages.Trial_Button_Msg}</span>
                </a>
                </div>
                <div className='right-section'>
                <img src={home}></img>
                </div>
        </div>
    </div>
    </div>
  )
}

export default Home