const Messages = {
    Sell_Online:"No code. No app required.",
    Successful_brand:"World's most succsessful brands are using Supershoply.",
    Trial_Button_Msg:"Start your 14-days free trial",
    Company_Address_Msg:"Reception Unit A, Aston Seedbed Centre, Avenue Road, Birmingham, England, B7 4NT",
    Copyright_Msg:"©2023 Supershoply - All rights reserved.",
    Setup_Store:"Set up your store, pick a plan later",
    Try_Supershoply_Days:"Try Supershoply free for 3 days, Cancel anytime",
    
}

export default Messages;